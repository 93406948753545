import { AxiosError } from 'axios';
import { UserFormData } from 'Components/Users/UserForm/UserForm.types';
import { TrackEventName } from 'Constants/AnalyticsEvent.enum';
import { QueryKey } from 'Constants/QueryKeys.enum';
import { MutationHookOptions } from 'hooks/MutationHooks/MutationHook.types';
import { backendApi, ErrorResponse } from 'http/Request';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { UserRole } from 'Types/User.types';
import { useTrack } from 'Utils/analytics';
import { ReportUserRefData } from 'Types/Reports.types';

// add user
export const useAddUserMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation((params: { data: UserFormData }) => backendApi.post(`/users`, params.data), {
    onError: (error: AxiosError<ErrorResponse>) => {
      options && options.onError(error.response?.data);
    },
    onSuccess: (res) => {
      invalidateQueries(queryClient);
      queryClient.invalidateQueries(QueryKey.Report);
      queryClient.invalidateQueries(QueryKey.CustomReportData);
      options && options.onSuccess(res.data);
    }
  });
};

// update user details
export const useUpdateUserMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: { id: number; data: UserFormData }) => backendApi.put(`/users/${params.id}`, params.data),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (res) => {
        invalidateQueries(queryClient);
        queryClient.invalidateQueries(QueryKey.Report);
        queryClient.invalidateQueries(QueryKey.CustomReportData);
        options && options.onSuccess(res.data);
      }
    }
  );
};

// change role
export const useUserRoleChangeMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: { user: { value: number; data: ReportUserRefData }; role: UserRole }) =>
      backendApi.put(`/system-users/${params.user.value}/role`, {
        role: params.role
      }),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (_, v) => {
        invalidateQueries(queryClient);
        options && options.onSuccess(v);
      }
    }
  );
};

// deactivate
export const useDeactivateUserMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (user: { value: number; data: ReportUserRefData }) => backendApi.delete(`/system-users/${user.value}/loginState`),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (_, v) => {
        invalidateQueries(queryClient);
        options && options.onSuccess(v);
      }
    }
  );
};

// invite
export interface InvitationsMutationParams {
  name?: string;
  email?: string;
  userId?: number;
}
export const useInvitationsMutation = (options?: MutationHookOptions) => {
  const { track } = useTrack();
  const queryClient = useQueryClient();

  return useMutation(
    (params: InvitationsMutationParams) =>
      backendApi.post('/invitations', {
        ...(params.userId && { userId: params.userId }),
        ...(params.name && { name: params.name }),
        ...(params.email && { email: params.email })
      }),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (_, variables) => {
        track({ eventName: TrackEventName.InviteUser, params: variables });
        invalidateQueries(queryClient);
        options && options.onSuccess(variables);
      }
    }
  );
};

export const useBulkInvitationsMutation = (options?: MutationHookOptions) => {
  const { track } = useTrack();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { data: { email?: string; userId?: number }[] }) => backendApi.post('/invitations/bulk', params),
    {
      onError: (error: AxiosError<ErrorResponse>) => {
        options && options.onError(error.response?.data);
      },
      onSuccess: (res, variables) => {
        track({ eventName: TrackEventName.InviteUser, params: variables });
        invalidateQueries(queryClient);
        options && options.onSuccess(res.data);
      }
    }
  );
};

//
// check user mbox sync status

export const useUserSyncStatusMutation = (options?: MutationHookOptions) => {
  const queryClient = useQueryClient();

  return useMutation((userId: number) => backendApi.post(`/users/${userId}/sync-status`), {
    onError: (error: AxiosError<ErrorResponse>) => {
      options && options.onError(error.response?.data);
    },
    onSuccess: (res) => {
      invalidateQueries(queryClient);
      options && options.onSuccess(res.data);
    }
  });
};

const invalidateQueries = (queryClient: QueryClient): void => {
  queryClient.invalidateQueries(QueryKey.User);
  queryClient.invalidateQueries(QueryKey.CustomReportData);
  queryClient.invalidateQueries(QueryKey.UsersOverview);
};
