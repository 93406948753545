import { ConditionGroup } from 'Components/Conditions';
import { Provider } from 'Types/Integrations.types';
import { FieldTypeData } from 'Types/Shared.types';
import { CustomReportColumn, CustomReportArguments } from 'Types/Reports.types';

export enum FieldType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
  DateTime = 'DateTime',
  UserReference = 'UserReference',
  List = 'List'
}

export enum FieldAggregationType {
  Sum = 'Sum',
  Avg = 'Avg',
  None = 'None'
}

export type FieldEntityType = 'Customer' | 'Stakeholder';
export type FieldDefinitionType = 'System' | 'Virtual' | 'Custom';

export interface FieldMetadata {
  id: number;
  fieldId: string;
  objectType: FieldEntityType;
  fieldType: FieldType;
  typeData?: FieldTypeData;
  definitionType: FieldDefinitionType;
  label: string;
  integration: FieldMetadataIntegration | null;
  editable: boolean;
  aggregation: FieldAggregationType;
  formatter: FieldFormatterType | null;
}

export enum FieldFormatterType {
  Currency = 'Currency',
  Percentage = 'Percentage',
  Url = 'Url'
}

export interface FieldMetadataIntegration {
  provider: Provider;
  externalField: ExternalField;
}

export interface ExternalField {
  id: string;
  label: string;
  type: FieldType;
}

export interface FieldProviderMetadata {
  provider: Provider;
  fields: ExternalField[];
  definitionTypes: FieldDefinitionType[];
}

export interface ProviderUploadFields {
  filter: ConditionGroup;
  arguments: CustomReportArguments;
  fields: { column: CustomReportColumn; externalField: ExternalField }[];
}
