import { CustomerStatus } from 'Types/Customer.types';
import { CUSTOMER_ENTITY } from 'Types/ReportField.types';
import { CustomReportConfig } from 'Types/Reports.types';
import { translate } from 'Utils/translate';

export const CUSTOMER_ESSENTIALS_REPORT_CONFIG: CustomReportConfig = {
  entity: CUSTOMER_ENTITY,
  columns: [
    { key: 'customer', field: { id: 'customer.id' } },
    { key: 'status', field: { id: 'customer.status' } }
  ],
  filter: { operator: 'And', conditions: [] },
  arguments: {}
};

export const CustomerStatusLabel: Record<CustomerStatus, string> = {
  [CustomerStatus.Active]: translate('common:customer-status.active'),
  [CustomerStatus.Churned]: translate('common:customer-status.churned'),
  [CustomerStatus.Prospect]: translate('common:customer-status.prospect')
};
