import * as Sentry from '@sentry/react';
import { ReactElement } from 'react';

interface ErrorBoundaryProps {
  fallback: ReactElement;
  children: ReactElement;
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ fallback, children }) => (
  <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
);
