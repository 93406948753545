import { useEffect, useState } from 'react';
import { UserEssentials } from 'Types/User.types';
import { useUsersEssentialsQuery } from './useUsersQuery';

export type UsersMap = Map<number, UserEssentials>;

export const useUsersMap = (options?: { enabled?: boolean }): UsersMap => {
  const [userMap, setUserMap] = useState<UsersMap>(new Map());

  const { data } = useUsersEssentialsQuery({
    staleTime: Infinity,
    enabled: options?.enabled !== false
  });

  useEffect(() => {
    if (!data || data.data.length < 1) return;
    setUserMap(new Map(data.data.map((d: UserEssentials) => [d.user.value, d])));
  }, [data]);

  return userMap;
};
