// taken from stackoverflow:
// https://stackoverflow.com/questions/57815244/highlight-multiple-strings-in-a-statement-javascript

import React, { ReactNode, useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';

export interface HighlightProps {
  text: string | null;
  wholeWordOnly: boolean;
  highlight?: string[];
}

/* eslint-disable no-useless-escape */
const escapeRegex = (word: string) => word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

const HighlightTerms: React.FC<Required<HighlightProps>> = ({ text, wholeWordOnly, highlight }) => {
  const displayText = useMemo(() => {
    if (!text) return '';
    if (highlight.length === 0) {
      return text;
    }

    // '\\b' + pattern + '\\b' matches whole word only
    const pattern = highlight
      .map(escapeRegex)
      .filter((term) => term.length > 0)
      .map((term) => (wholeWordOnly ? ('\\b' + term + '\\b').concat('|<[^>]*>') : term))
      .join('|');

    const regex = new RegExp(pattern, 'gi');

    const highlightedText = text.replace(regex, (match) =>
      /<[^>]*>/g.test(match) ? match : `<span class="highlight">${match}</span>`
    );

    return sanitizeHtml(highlightedText, {
      disallowedTagsMode: 'escape',
      allowedTags: ['span'],
      allowedClasses: {
        span: ['highlight']
      }
    });
  }, [text, wholeWordOnly, highlight]);

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: displayText
      }}
    />
  );
};

export const Highlight: React.FC<HighlightProps> = ({ text, wholeWordOnly, highlight }) => {
  const content = useMemo(
    (): ReactNode =>
      highlight && highlight.length > 0 ? (
        <HighlightTerms text={text} wholeWordOnly={wholeWordOnly} highlight={highlight} />
      ) : (
        text
      ),
    [text, wholeWordOnly, highlight]
  );

  return <>{content}</>;
};
