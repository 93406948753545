import { USER_ENTITY } from 'Types/ReportField.types';
import { CustomReportConfig } from 'Types/Reports.types';

export const USER_ESSENTIALS_REPORT_CONFIG: CustomReportConfig = {
  entity: USER_ENTITY,
  columns: [
    { key: 'user', field: { id: 'user.id' } },
    { key: 'manager', field: { id: 'user.manager' } }
  ],
  filter: { operator: 'And', conditions: [] },
  arguments: {}
};
