import React, { CSSProperties, ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { times8 } from 'Constants/Styles';
import classNames from 'classnames';
import { useVirtualizer } from '@tanstack/react-virtual';
import { uniqFast } from 'Utils/functional';
import { Button, Checkbox, CheckboxChangeEvent, Input } from 'Components/Primitives';
import { CheckboxSelectOption } from 'Components/CheckboxSelect/CheckboxSelect.types';
import { StyledCheckboxSelect } from 'Components/CheckboxSelect/StyledCheckboxSelect';

export interface CheckboxSelectVirtualProps {
  value?: (string | number)[] | null;
  defaultValue?: (string | number)[] | undefined;
  options: CheckboxSelectOption[];
  maxHeight?: number;
  showAllOption?: boolean;
  searchPlaceholder?: string;
  virtualItemHeight?: number;
  style?: CSSProperties;
  className?: string;
  onChange?(values: (string | number)[]): void;
}

const StyledCheckboxSelectVirtual = styled(StyledCheckboxSelect)<{ maxHeight: number | undefined }>`
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '100%')};
  overflow: auto;
  .checkbox-select-header {
    margin-bottom: ${times8()}px;
  }
`;

const StyledCheckboxSelectOption = styled.div<{ start: number; itemHeight: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ itemHeight }) => `${itemHeight}px`};
  transform: ${({ start }) => `translateY(${start}px)`};
  .ant-checkbox-wrapper .ant-checkbox {
    align-self: center;
  }
  .option-label {
    display: block;
    line-height: 1;
  }
`;

const DEFAULT_ITEM_HEIGHT = times8(4);

export const CheckboxSelectVirtual: React.FC<CheckboxSelectVirtualProps> = ({
  value,
  defaultValue,
  options,
  maxHeight = 400,
  showAllOption = true,
  searchPlaceholder,
  virtualItemHeight = DEFAULT_ITEM_HEIGHT,
  style,
  className,
  onChange
}) => {
  const { t } = useTranslation(['common']);
  const [listOptions, setListOptions] = useState<CheckboxSelectOption[]>(options);
  const [searchVal, setSearchVal] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<(string | number)[]>([]);

  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: listOptions.length,
    getScrollElement: () => parentRef.current || null,
    estimateSize: () => virtualItemHeight
  });

  // check all state
  const checkAll = useMemo((): boolean => selectedValues.length === options.length, [selectedValues, options]);
  const checkAllIndeterminate = useMemo(
    (): boolean => !checkAll && selectedValues.length > 0 && selectedValues.length < options.length,
    [checkAll, selectedValues, options]
  );

  const partialCheckAll = useMemo(
    (): boolean => listOptions.map((o) => o.value).every((o) => selectedValues.includes(o)),
    [selectedValues, listOptions]
  );
  const partialCheckAllIndeterminate = useMemo(
    (): boolean => !partialCheckAll && listOptions.map((o) => o.value).some((o) => selectedValues.includes(o)),
    [partialCheckAll, selectedValues, listOptions]
  );

  // emit change
  const emitChange = useCallback(
    (changedValues: (string | number)[]) => {
      onChange && onChange(changedValues);
    },
    [onChange]
  );

  // search
  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.target.value || '');
  }, []);

  // check select all
  const onCheckAllChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const listOptionsValues = listOptions.map((option) => option.value);
      const changedValues = e.target.checked
        ? uniqFast([...selectedValues, ...listOptionsValues])
        : selectedValues.filter((v) => !listOptionsValues.includes(v));
      setSelectedValues(changedValues);
      emitChange(changedValues);
    },
    [selectedValues, listOptions, emitChange]
  );

  // check option
  const onOptionChange = useCallback(
    (e: CheckboxChangeEvent, option: CheckboxSelectOption) => {
      const changedValues = e.target.checked
        ? [...selectedValues, option.value]
        : selectedValues.filter((v) => v !== option.value);
      setSelectedValues(changedValues);
      emitChange(changedValues);
    },
    [selectedValues, emitChange]
  );

  // reset
  const isResetDisabled = useMemo(
    (): boolean =>
      defaultValue
        ? defaultValue.length === selectedValues.length && defaultValue.every((v) => selectedValues.includes(v))
        : true,
    [defaultValue, selectedValues]
  );
  const onResetClick = useCallback(() => {
    defaultValue && setSelectedValues(defaultValue);
  }, [defaultValue]);

  // update list by search val
  useEffect(() => {
    setListOptions(
      searchVal.length > 0
        ? options.filter((option) => option.searchValue.toLowerCase().includes(searchVal.toLowerCase()))
        : options
    );
  }, [options, searchVal]);

  // initialize list options
  useEffect(() => {
    setListOptions(options);
    setSearchVal('');
  }, [options]);

  // update values
  useEffect(() => {
    value && setSelectedValues(value);
  }, [value]);

  return (
    <StyledCheckboxSelectVirtual ref={parentRef} maxHeight={maxHeight} style={style} className={className}>
      <div className="checkbox-select-header">
        {options.length > 10 && (
          <div className="checkbox-select-search">
            <Input
              data-testid="checkbox-select-search-input"
              autoFocus={true}
              value={searchVal}
              placeholder={searchPlaceholder || t('search')}
              size="middle"
              onChange={onSearchChange}
              allowClear={true}
            />
          </div>
        )}

        {showAllOption && (
          <div className={classNames('checkbox-select-all-wrapper', searchVal && searchVal.length > 0 && 'filtered')}>
            <Checkbox
              data-testid="checkbox-select-all"
              className="checkbox-select-option select-all-cb"
              onChange={onCheckAllChange}
              checked={checkAll}
              indeterminate={checkAllIndeterminate}
            >
              {t('all')}
            </Checkbox>
            <Checkbox
              data-testid="checkbox-select-matching"
              className="checkbox-select-option select-matching-cb"
              onChange={onCheckAllChange}
              checked={partialCheckAll}
              indeterminate={partialCheckAllIndeterminate}
            >
              {t('all-matching')}
            </Checkbox>

            {selectedValues && <div className="total-selected">Selected ({selectedValues.length})</div>}
          </div>
        )}
      </div>

      <div
        className="checkbox-select-list"
        data-testid="checkbox-select"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative'
        }}
      >
        {/* Only the visible items in the virtualizer, manually positioned to be in view */}
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const option = listOptions[virtualItem.index];
          return (
            <StyledCheckboxSelectOption
              key={virtualItem.key}
              data-testid="checkbox-select-option-wrapper"
              className="checkbox-select-option"
              start={virtualItem.start}
              itemHeight={virtualItemHeight}
            >
              <Checkbox
                className="option-checkbox"
                data-testid="checkbox-select-option"
                checked={selectedValues.includes(option.value)}
                onChange={(e) => onOptionChange(e, option)}
              >
                <span className="option-label">{listOptions[virtualItem.index].label}</span>
              </Checkbox>
            </StyledCheckboxSelectOption>
          );
        })}
      </div>

      {defaultValue && (
        <div className="footer">
          <Button type="link" data-testid="reset-to-default-button" onClick={onResetClick} disabled={isResetDisabled}>
            {t('reset')}
          </Button>
        </div>
      )}
    </StyledCheckboxSelectVirtual>
  );
};
