import { AvatarBackgroundColors, ChartColors } from 'Constants/Styles';

/**
 *
 * @param color : color in hex value;
 * @param brightness : value between -1 and 1, -1 = black, 1 = white
 * @returns color in hex value;
 *
 * taken from https://gist.github.com/renancouto/4675192
 */
export const getColorWithBrightness = (colorCode: string, brightness: number): string => {
  const num = parseInt(colorCode.replace('#', ''), 16);
  const amt = Math.round(255 * brightness);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

export const getColorByText = (text: string, colors: string[] = AvatarBackgroundColors): string =>
  colors[text.length % colors.length];

export const getColorByIndex = (index: number, colors: string[] = ChartColors) => {
  const brightness = Math.floor(index / colors.length) * 0.1;
  return getColorWithBrightness(colors[index % colors.length], brightness);
};

// https://convertingcolors.com/blog/article/convert_hex_to_rgb_with_javascript.html
const hexToRgb = (hex: string): [number, number, number] | null => {
  const hexColor = hex.replace('#', '');
  if (hexColor.length !== 6) {
    console.warn('Only six-digit hex colors are allowed.', hexColor);
    return null;
  }
  const aRgbHex = hexColor.match(/.{1,2}/g);
  return aRgbHex ? [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)] : null;
};

// https://awik.io/determine-color-bright-dark-using-javascript/
export const isColorDark = (checkColor: string): boolean => {
  let r: number = 0;
  let g: number = 0;
  let b: number = 0;
  // If RGB --> store the red, green, blue values in separate variables
  if (checkColor.match(/^rgb/)) {
    const color: RegExpMatchArray | null = checkColor.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );
    if (color) {
      r = parseInt(color[1]);
      g = parseInt(color[2]);
      b = parseInt(color[3]);
    }
  } else {
    // If HEX --> Convert it to RGB
    const rgbColor = hexToRgb(checkColor);
    if (rgbColor) {
      r = rgbColor[0];
      g = rgbColor[1];
      b = rgbColor[2];
    }
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return hsp < 127.5;
};
