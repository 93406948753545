export const getUserInitials = (firstName?: string, lastName?: string): string | undefined => {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }
  if (firstName) {
    return firstName[0];
  }
  if (lastName) {
    return lastName[0];
  }
  return undefined;
};

export const normalizedStringValue = (str: string, lowerCase = false): string => {
  const strWithoutAccents = str.normalize('NFD').replaceAll(/([\u0300-\u036f])/g, '');
  return lowerCase ? strWithoutAccents.toLocaleLowerCase() : strWithoutAccents;
};

export const numberWithCommas = (num: number): string => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//
// percent number input should be between 0 and 1
export const numberAsPercent = (num: number): string => {
  const percentValue = num * 100;
  const roundedNumber = Math.round(percentValue * 100) / 100;
  const formattedNumber = roundedNumber.toFixed(2);

  return `${roundedNumber === parseInt(roundedNumber.toString(), 10) ? roundedNumber.toString() : formattedNumber}%`;
};

// replaces multi line breaks with a double line break for formatting purposes
const removeExcessiveLineBreaks = (str: string): string => str.replace(/\n\s*\n/g, '\n\n');

// remove excessive line breaks and removes extra spaces
export const cleanupText = (str: string): string => removeExcessiveLineBreaks(str.replaceAll('&nbsp;', '\n')).trim();

export const replaceSubstring = (
  originalText: string,
  startIndex: number,
  length: number,
  replacement: string
): string => {
  // Ensure valid startIndex and length values
  if (startIndex < 0 || length < 0 || startIndex + length > originalText.length) {
    console.error('Invalid start index or length.');
    return originalText;
  }

  const prefix = originalText.slice(0, startIndex);
  const suffix = originalText.slice(startIndex + length);

  return `${prefix}${replacement}${suffix}`;
};

export const containsLineBreak = (str: string): boolean => {
  const lineBreakRegex = /\n/;
  return lineBreakRegex.test(str);
};

// replace *** with <b></b> tags
export const replaceAsterisksWithBoldTag = (str: string): string => str.replace(/\*\*([^*]+?)\*\*/gs, '<b>$1</b>');
