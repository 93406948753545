import { useCallback, useMemo } from 'react';
import { TreeNode } from 'Components/Primitives';
import { useCustomReportDataQueryWithParam, useReportEntityMetadataQueryWithParam } from 'hooks';
import { getEntityMetadataFieldMap } from 'Views/Reports/CustomReport/CustomReportUtils';
import { CustomReportConfig } from 'Types/Reports.types';

const BASE_CONFIG: Pick<CustomReportConfig, 'arguments' | 'filter'> = {
  arguments: {},
  filter: { operator: 'And', conditions: [] }
};
export const useEntityRefValuesFromEntityMetadata = (): {
  getEntityRefValuesFromEntityMetadata(entity: string): Promise<TreeNode[]>;
} => {
  const { fetch: fetchMetadata } = useReportEntityMetadataQueryWithParam();
  const { fetch: fetchData } = useCustomReportDataQueryWithParam();

  const getEntityRefValuesFromEntityMetadata = useCallback(
    async (entity: string) => {
      const metadata = await fetchMetadata(entity);
      if (!metadata) return [];

      const fieldsMap = getEntityMetadataFieldMap(metadata?.entries);
      const csvField = fieldsMap[`${entity}.id`]?.type?.renderingConfiguration?.CsvField;
      if (!csvField) return [];

      const data = await fetchData({
        ...BASE_CONFIG,
        entity,
        columns: [
          { key: 'id', field: { id: `${entity}.id` } },
          { key: 'label', field: { id: csvField } }
        ]
      });
      if (!data) return [];
      return data.data.map((row) => ({
        title: row.label.value as string,
        searchValue: row.label.value as string,
        value: row.id.value as string | number,
        selectable: true,
        children: []
      }));
    },
    [fetchMetadata, fetchData]
  );

  const res = useMemo(() => ({ getEntityRefValuesFromEntityMetadata }), [getEntityRefValuesFromEntityMetadata]);

  return res;
};
