import {
  convertPaginationToGetRange,
  convertDateRangeValueToQueryDateRange,
  QueryPagination
} from 'Components/Filters';
import { FilterMetadataApiKeys } from 'Components/Filters/FilterApi.consts';
import { messageDuration, useMessage } from 'Components/Primitives';
import { UserWeeklyDigestData } from 'Components/Users/UserWeeklyDigest/UserWeeklyDigest.types';
import { QueryKey } from 'Constants/QueryKeys.enum';
import { backendApi } from 'http/Request';
import { QueryObserverOptions, UseQueryResult, useQuery } from 'react-query';
import { DateRangeValue } from 'Types/DateRange.types';
import { ActiveInactive } from 'Types/Shared.types';
import { User, UserEssentials, UserOverview, UserStatistics } from 'Types/User.types';
import { useCustomReportDataQuery } from 'hooks/QueryHooks/useCustomReportQuery';
import { CustomReportData } from 'Types/Reports.types';
import { USER_ESSENTIALS_REPORT_CONFIG } from 'Components/Users/Users.consts';

//
// useUserQuery
export const useUserQuery = (params: { userId: number }, options?: QueryObserverOptions<User>) => {
  const message = useMessage();

  return useQuery<User>(
    [QueryKey.User, params],
    () =>
      backendApi
        .get<User>(`/users/${params.userId}`)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

//
// useUsersOverviewQuery
export interface UsersOverviewQueryParams {
  filters?: {
    [FilterMetadataApiKeys.UserLoginStateKey]?: ActiveInactive;
    [FilterMetadataApiKeys.UserIdKey]?: number[];
  };
  pagination?: QueryPagination;
}
export const useUsersOverviewQuery = (
  params: UsersOverviewQueryParams,
  options?: QueryObserverOptions<{ data: UserOverview[] }>
) => {
  const message = useMessage();

  return useQuery<{ data: UserOverview[] }>(
    [QueryKey.UsersOverview, params],
    () =>
      backendApi
        .get<{ data: UserOverview[] }>('/data/users-overview', {
          params: {
            ...(params.filters?.[FilterMetadataApiKeys.UserLoginStateKey] && {
              loginState: params.filters[FilterMetadataApiKeys.UserLoginStateKey]
            }),
            ...(params.filters?.[FilterMetadataApiKeys.UserIdKey] && {
              userId: params.filters[FilterMetadataApiKeys.UserIdKey]
            }),
            ...(params.pagination && { range: convertPaginationToGetRange(params.pagination) })
          }
        })
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      retry: false,
      ...(options && { ...options })
    }
  );
};

//
// useUserStatisticsQuery
interface UserStatisticsParams {
  userId: number;
  dateRange: DateRangeValue;
}

export const useUserStatisticsQuery = (
  params: UserStatisticsParams,
  options?: QueryObserverOptions<UserStatistics>
) => {
  const message = useMessage();

  return useQuery<UserStatistics>(
    [QueryKey.UserStatistics, params],
    () =>
      backendApi
        .get<UserStatistics>(`/users/${params.userId}/statistics`, {
          params: { dateRange: convertDateRangeValueToQueryDateRange(params.dateRange) }
        })
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, ...(options && { ...options }) }
  );
};

//
// user weekly digest by id - if id is different then the logged in
// user, the response will be visible for admins / managers only, users will get null
//
export const useUserWeeklyDigestByIdQuery = (
  params: { userId: number },
  options?: QueryObserverOptions<UserWeeklyDigestData | null>
) => {
  const message = useMessage();

  return useQuery<UserWeeklyDigestData | null>(
    [QueryKey.WeeklyDigestById, params],
    () =>
      backendApi
        .get<UserWeeklyDigestData | null>(`/users/${params.userId}/digest`)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      ...{ retry: false, staleTime: Infinity },
      ...(options && { ...options })
    }
  );
};

//
// user weekly digest - returns the digest of the logged in user
//
export const useUserWeeklyDigestQuery = (options?: QueryObserverOptions<UserWeeklyDigestData | null>) => {
  const message = useMessage();

  return useQuery<UserWeeklyDigestData | null>(
    [QueryKey.WeeklyDigest],
    () =>
      backendApi
        .get<UserWeeklyDigestData | null>(`/users/digest`)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      ...{ retry: false, staleTime: Infinity },
      ...(options && { ...options })
    }
  );
};

//
// useUsersEssentialsQuery
//
export const useUsersEssentialsQuery = (
  options?: QueryObserverOptions<{ data: CustomReportData[] }>
): UseQueryResult<{ data: UserEssentials[] }> =>
  useCustomReportDataQuery(USER_ESSENTIALS_REPORT_CONFIG, { ...options, staleTime: Infinity }) as UseQueryResult<{
    data: UserEssentials[];
  }>;
