import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FeatureFlag } from 'Constants/FeatureFlag.enum';
import isEqual from 'fast-deep-equal';
import { atom, useAtom, useSetAtom } from 'jotai';
import { nonNullable } from 'Utils/functional';
import { useFeatureFlagDataQuery } from 'hooks';

export type FeatureFlagMap = Map<FeatureFlag, boolean>;
export const URLFeatureFlagAtom = atom<{ [key: string]: 'on' | 'off' } | null>(null);

export const useIsFeatureFlagReady = (): boolean => {
  const setUrlFF = useSetAtom(URLFeatureFlagAtom);
  const [searchParams] = useSearchParams();
  const [urlFF] = useState(searchParams.get('ff'));

  useEffect(() => {
    if (!urlFF) return;
    const flags = urlFF.split(',');
    setUrlFF(
      flags.reduce((acc: { [key: string]: 'on' | 'off' }, flag) => {
        const [key, value] = flag.split('=');
        acc[key] = value === 'off' ? 'off' : 'on';
        return acc;
      }, {})
    );
  }, [urlFF, setUrlFF]);

  const { data } = useFeatureFlagDataQuery();
  return !!data;
};

export const useFeatureFlags = (featureFlags: FeatureFlag[]): FeatureFlagMap => {
  const [featureFlagList, setFeatureFlagList] = useState<FeatureFlag[]>(featureFlags);
  const [urlFF] = useAtom(URLFeatureFlagAtom);

  const { data } = useFeatureFlagDataQuery();

  useEffect(() => {
    setFeatureFlagList((cur) => (isEqual(cur, featureFlags) ? cur : featureFlags));
  }, [featureFlags]);

  const treatmentsMap = useMemo(() => {
    const enabledUrlFF = urlFF
      ? Object.entries(urlFF)
          .filter(([_, value]) => value === 'on')
          .map(([key]) => key)
      : [];
    const disabledUrlFF = urlFF
      ? Object.entries(urlFF)
          .filter(([_, value]) => value === 'off')
          .map(([key]) => key)
      : [];

    return data
      ? new Map(
          featureFlagList.map((ff) => [
            ff,
            [...data.data, ...enabledUrlFF]
              .filter((d) => !disabledUrlFF.includes(d))
              .filter(nonNullable)
              .includes(ff) || false
          ])
        )
      : new Map();
  }, [data, urlFF, featureFlagList]);

  return treatmentsMap;
};

export const useIsFeatureFlagEnabled = (featureFlag: FeatureFlag):boolean => {
  const map = useFeatureFlags([featureFlag]);
  return map.get(featureFlag) || false;
}
