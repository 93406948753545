import { FieldListItem } from 'Types/Shared.types';

export enum ReportFieldType {
  Boolean = 'Boolean',
  Collection = 'Collection',
  Date = 'Date',
  DateTime = 'DateTime',
  DateRange = 'DateRange',
  Number = 'Number',
  Picklist = 'Picklist',
  Reference = 'Reference',
  String = 'String',
  Void = 'Void'
}

export enum ReportArgumentFieldType {
  Boolean = 'Boolean',
  DateRange = 'DateRange',
  Filter = 'Filter',
  Picklist = 'Picklist'
}

export const USER_ENTITY = 'user';
export const CUSTOMER_ENTITY = 'customer';
export const STAKEHOLDER_ENTITY = 'stakeholder';
export const PARTICIPANT_ENTITY = 'participant';
export const MEETING_ENTITY = 'meeting';
export const SYSTEM_USER_ENTITY = 'system_user';

export interface ReportFieldTypeBase {
  name: Exclude<ReportFieldType, ReportFieldType.Reference | ReportFieldType.Collection | ReportFieldType.Picklist>;
  renderingConfiguration?: {
    DisplayType?: ReportRenderConfigType;
    HighlightNegativeAbove?: boolean;
    HighlightNegativeBelow?: boolean;
    CsvField?: string;
  };
}

export interface ReportFieldTypeFilter extends Omit<ReportFieldTypeBase, 'name'> {
  name: ReportArgumentFieldType.Filter;
  data: { referencedEntity: string };
}

export interface ReportFieldTypeReference extends Omit<ReportFieldTypeBase, 'name'> {
  name: ReportFieldType.Reference;
  data: {
    referencedEntity: string;
  };
}

export interface ReportFieldTypePickList extends Omit<ReportFieldTypeBase, 'name'> {
  name: ReportFieldType.Picklist;
  data: {
    listEntries: FieldListItem[];
  };
}

export interface ReportFieldTypeCollection extends Omit<ReportFieldTypeBase, 'name'> {
  name: ReportFieldType.Collection;
  data: {
    elementType: ReportFieldTypeBase | ReportFieldTypePickList | ReportFieldTypeReference;
  };
}

export enum ReportRenderConfigType {
  Currency = 'Currency',
  Score = 'Score',
  Percentage = 'Percentage',
  CrmCustomerLink = 'CrmCustomerLink',
  CrmStakeholderLink = 'CrmStakeholderLink'
}

export enum ReportFilterOperator {
  In = 'In',
  NotIn = 'NotIn',
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  GreaterOrEqual = 'GreaterOrEqual',
  GreaterThan = 'GreaterThan',
  LessOrEqual = 'LessOrEqual',
  LessThan = 'LessThan',
  InRange = 'InRange',
  NotInRange = 'NotInRange',
  Contains = 'Contains',
  NotContains = 'NotContains',
  Include = 'Include',
  NotInclude = 'NotInclude',
  Defined = 'Defined',
  NotDefined = 'NotDefined'
}

export const ReportFilterOperatorByFieldType: Record<
  ReportFieldType | ReportArgumentFieldType,
  ReportFilterOperator[]
> = {
  [ReportFieldType.Picklist]: [
    ReportFilterOperator.In,
    ReportFilterOperator.NotIn,
    ReportFilterOperator.Defined,
    ReportFilterOperator.NotDefined
  ],
  [ReportFieldType.Reference]: [
    ReportFilterOperator.In,
    ReportFilterOperator.NotIn,
    ReportFilterOperator.Defined,
    ReportFilterOperator.NotDefined
  ],
  [ReportFieldType.String]: [
    ReportFilterOperator.Equal,
    ReportFilterOperator.NotEqual,
    ReportFilterOperator.Contains,
    ReportFilterOperator.NotContains,
    ReportFilterOperator.Defined,
    ReportFilterOperator.NotDefined
  ],
  [ReportFieldType.Boolean]: [
    ReportFilterOperator.Equal,
    ReportFilterOperator.NotEqual,
    ReportFilterOperator.Defined,
    ReportFilterOperator.NotDefined
  ],
  [ReportFieldType.Collection]: [ReportFilterOperator.Include, ReportFilterOperator.NotInclude],
  [ReportFieldType.Date]: [
    ReportFilterOperator.InRange,
    ReportFilterOperator.NotInRange,
    ReportFilterOperator.GreaterOrEqual,
    ReportFilterOperator.GreaterThan,
    ReportFilterOperator.LessOrEqual,
    ReportFilterOperator.LessThan,
    ReportFilterOperator.Equal,
    ReportFilterOperator.NotEqual,
    ReportFilterOperator.Defined,
    ReportFilterOperator.NotDefined
  ],
  [ReportFieldType.DateTime]: [
    ReportFilterOperator.InRange,
    ReportFilterOperator.NotInRange,
    ReportFilterOperator.GreaterOrEqual,
    ReportFilterOperator.GreaterThan,
    ReportFilterOperator.LessOrEqual,
    ReportFilterOperator.LessThan,
    ReportFilterOperator.Equal,
    ReportFilterOperator.NotEqual,
    ReportFilterOperator.Defined,
    ReportFilterOperator.NotDefined
  ],
  [ReportFieldType.DateRange]: [
    ReportFilterOperator.InRange,
    ReportFilterOperator.NotInRange,
    ReportFilterOperator.Defined,
    ReportFilterOperator.NotDefined
  ],
  [ReportFieldType.Number]: [
    ReportFilterOperator.Equal,
    ReportFilterOperator.NotEqual,
    ReportFilterOperator.GreaterOrEqual,
    ReportFilterOperator.GreaterThan,
    ReportFilterOperator.LessOrEqual,
    ReportFilterOperator.LessThan,
    ReportFilterOperator.Defined,
    ReportFilterOperator.NotDefined
  ],
  [ReportArgumentFieldType.Filter]: [],
  [ReportFieldType.Void]: []
};
