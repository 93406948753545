import React from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import Plus from 'Assets/Icons/plus.svg?react';
import { ButtonProps } from 'Components/Primitives';
import { Colors, times8 } from 'Constants/Styles';
import { getColorWithBrightness } from 'Utils/colors';

export interface RoundAddButtonProps {
  title: string;
  open?: boolean;
  showTitleOnHover?: boolean;
  className?: string;
  'data-testid'?: string;
  onClick?: ButtonProps['onClick'];
}

const StyledRoundAddButton = styled.button`
  display: inline-flex;
  align-items: center;
  color: ${Colors.Primary};
  .plus-circle {
    border: 1px solid ${Colors.Primary};
    transition: transform 0.15s ease-out;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    .plus-svg {
      display: block;
      font-size: 14px;
    }
  }
  &.open .plus-circle {
    transform: rotate(45deg);
  }
  &.show-title-on-hover {
    .plus-title {
      white-space: nowrap;
      pointer-events: none;
      width: 0;
      opacity: 0;
      transition:
        transform 0.15s ease-out,
        opacity 0.15s ease-out;
      transform: translateX(-${times8(0.5)}px);
    }
    &:hover {
      .plus-title {
        width: auto;
        pointer-events: auto;
        opacity: 1;
        transform: translateX(0);
      }
      .plus-circle {
        transform: rotate(90deg);
      }
    }
  }
  &:hover {
    color: ${getColorWithBrightness(Colors.Primary, 0.2)} !important;
    .plus-circle {
      border-color: ${getColorWithBrightness(Colors.Primary, 0.2)} !important;
    }
  }
`;

export const RoundAddButton: React.FC<RoundAddButtonProps> = ({
  title,
  open,
  showTitleOnHover,
  className,
  'data-testid': dataTestId,
  onClick
}) => (
  <StyledRoundAddButton
    data-testid={dataTestId}
    className={classNames(className, { open }, showTitleOnHover ? 'show-title-on-hover' : '')}
    onClick={onClick}
    type="button"
  >
    <div className="plus-circle">
      <Plus className="plus-svg" />
    </div>
    <span className="plus-title">{title}</span>
  </StyledRoundAddButton>
);
