import { ConditionGroup } from 'Components/Conditions';
import { FilterFormData, QueryPagination } from 'Components/Filters';
import { messageDuration, useMessage } from 'Components/Primitives';
import { TrackEventName } from 'Constants/AnalyticsEvent.enum';
import { QueryKey } from 'Constants/QueryKeys.enum';
import { backendApi, backendApiCsv } from 'http/Request';
import { useCallback } from 'react';
import { QueryObserverOptions, useQuery, useQueryClient } from 'react-query';
import {
  CustomReportData,
  CustomReportDefinition,
  CustomReportEntryMetadata,
  CustomReportMetadata,
  CustomReportConfig,
  ReportEntityTemplate
} from 'Types/Reports.types';
import { useTrack } from 'Utils/analytics';
import { setObjectFromKeys } from 'Utils/functional';

// custom reports
export const useCustomReportQuery = (options?: QueryObserverOptions<{ data: CustomReportDefinition[] }>) => {
  const message = useMessage();

  return useQuery<{ data: CustomReportDefinition[] }>(
    [QueryKey.ReportDefinitions],
    () =>
      backendApi
        .get<{ data: CustomReportDefinition[] }>('reports/definition')
        .then((response) => {
          return {
            data: response.data.data.map((report) => {
              return {
                ...report,
                config: {
                  ...report.config,
                  columns: report.config.columns
                }
              };
            })
          };
        })
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

// entities metadata
export const useReportEntityTemplatesQuery = (options?: QueryObserverOptions<{ entities: ReportEntityTemplate[] }>) => {
  const message = useMessage();

  return useQuery<{ entities: ReportEntityTemplate[] }>(
    [],
    () =>
      backendApi
        .get<{ entities: ReportEntityTemplate[] }>('reports/metadata')
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

// entity metadata
export const useReportEntityMetadataQuery = (
  entity: string,
  options?: QueryObserverOptions<{ entries: CustomReportEntryMetadata[] }>
) => {
  const message = useMessage();

  return useQuery<{ entries: CustomReportEntryMetadata[] }>(
    [QueryKey.ReportEntityMetadata, entity],
    () =>
      backendApi
        .get<{ entries: CustomReportEntryMetadata[] }>(`reports/metadata/${entity}`)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

export const useReportEntityMetadataQueryWithParam = () => {
  const queryClient = useQueryClient();

  const fetch = useCallback(
    async (entity: string): Promise<{ entries: CustomReportEntryMetadata[] }> => {
      const cachedData = queryClient.getQueryData<{ entries: CustomReportEntryMetadata[] }>([
        QueryKey.ReportEntityMetadata,
        entity
      ]);
      if (cachedData) return cachedData;

      const result = await queryClient.fetchQuery<{ entries: CustomReportEntryMetadata[] }>({
        queryKey: [QueryKey.ReportEntityMetadata, entity],
        queryFn: () =>
          backendApi
            .get<{ entries: CustomReportEntryMetadata[] }>(`reports/metadata/${entity}`)
            .then((response) => response.data),
        staleTime: Infinity
      });

      return result;
    },
    [queryClient]
  );

  return { fetch };
};

// account breakdown metadata
export const useCustomerDetailsBreakdownMetadataQuery = (
  options?: QueryObserverOptions<{ data: { id: string }[] }>
) => {
  const message = useMessage();

  return useQuery<{ data: { id: string }[] }>(
    [QueryKey.CustomerBreakdownMetadata],
    () =>
      backendApi
        .get<{ data: { id: string }[] }>('/metadata/fields-selection/customer/summary')
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};

// custom report data
interface CustomReportQueryParams extends CustomReportConfig {
  pagination?: QueryPagination;
}
export const useCustomReportDataQuery = (
  params: CustomReportQueryParams,
  options?: QueryObserverOptions<{ data: CustomReportData[] }>
) => {
  const message = useMessage();

  return useQuery<{ data: CustomReportData[] }>(
    [QueryKey.CustomReportData, params],
    () =>
      backendApi
        .post<{ data: CustomReportData[] }>('reports/data', params)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, ...(options && { ...options }) }
  );
};

export const useCustomReportDataQueryWithParam = () => {
  const queryClient = useQueryClient();

  const fetch = useCallback(
    async (params: CustomReportQueryParams): Promise<{ data: CustomReportData[] }> => {
      const cachedData = queryClient.getQueryData<{ data: CustomReportData[] }>([QueryKey.CustomReportData, params]);
      if (cachedData) return cachedData;

      const result = await queryClient.fetchQuery<{ data: CustomReportData[] }>({
        queryKey: [QueryKey.CustomReportData, params],
        queryFn: () =>
          backendApi.post<{ data: CustomReportData[] }>('reports/data', params).then((response) => response.data),
        staleTime: Infinity
      });

      return result;
    },
    [queryClient]
  );

  return { fetch };
};

// report download
export const useCustomReportDownloadQuery = (
  params: { id: number; metadata?: CustomReportMetadata; config: CustomReportConfig },
  options?: QueryObserverOptions<string>
) => {
  const message = useMessage();
  const { track } = useTrack();

  return useQuery<string>(
    [QueryKey.CustomReportData, params],
    () =>
      backendApiCsv
        .post<string>('reports/data', params.config)
        .then((response) => {
          track({
            eventName: TrackEventName.ReportDownload,
            params: { id: params.id, name: params.metadata?.name }
          });
          return response.data;
        })
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, ...(options && { ...options }) }
  );
};

// filter conversion
export const useConvertFilterToConditionQuery = (
  params: FilterFormData,
  options: QueryObserverOptions<ConditionGroup>
) => {
  const message = useMessage();

  return useQuery<ConditionGroup>(
    [QueryKey.ConvertFilterToCondition, params],
    () =>
      backendApi
        .post<ConditionGroup>('/reports/convert-filter', setObjectFromKeys(params))
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    { retry: false, staleTime: Infinity, ...(options && { ...options }) }
  );
};
