import { convertPaginationToGetRange, QueryPagination } from 'Components/Filters';
import { messageDuration, useMessage } from 'Components/Primitives';
import { QueryKey } from 'Constants/QueryKeys.enum';
import { backendApi } from 'http/Request';
import { QueryObserverOptions, useQuery, UseQueryResult } from 'react-query';
import { CustomerEssentials, HydratedCustomer } from 'Types/Customer.types';
import { useCustomReportDataQuery } from 'hooks/QueryHooks/useCustomReportQuery';
import { CustomReportData } from 'Types/Reports.types';
import { CUSTOMER_ESSENTIALS_REPORT_CONFIG } from 'Components/Customers/Customers.consts';

//
// customer essentials
//
export const useCustomersEssentialsQuery = (options?: QueryObserverOptions<{ data: CustomReportData[] }>) =>
  useCustomReportDataQuery(CUSTOMER_ESSENTIALS_REPORT_CONFIG, { ...options, staleTime: Infinity }) as UseQueryResult<{
    data: CustomerEssentials[];
  }>;

//
// hydrated customers
//
interface CustomersQueryParams {
  filters?: {
    customerId?: number[];
    status?: string[];
    owner?: number;
  };
  pagination?: QueryPagination;
}

export const useCustomersQuery = (
  params?: CustomersQueryParams,
  options?: QueryObserverOptions<{ data: HydratedCustomer[] }>
) => {
  const message = useMessage();

  return useQuery<{ data: HydratedCustomer[] }>(
    [QueryKey.Customers, params],
    () =>
      backendApi
        .get<{ data: HydratedCustomer[] }>('/customers', {
          params: {
            ...params?.filters,
            ...(params?.pagination && { range: convertPaginationToGetRange(params.pagination) })
          }
        })
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      retry: false,
      staleTime: Infinity,
      ...(options && { ...options })
    }
  );
};

//
// lookup customer id from crmid - used for iframes
//

export const useLookupCustomerIdQuery = (
  params: { crmId?: string; appId?: string },
  options?: QueryObserverOptions<{ id: number | null }>
) => {
  const message = useMessage();

  return useQuery<{ id: number | null }>(
    ['lookupCustomerId', params],
    () =>
      backendApi
        .post<{ id: number | null }>('/customers/lookup', params)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      retry: false,
      staleTime: Infinity,
      ...(options && { ...options })
    }
  );
};

//
// customer children by customer id
//
export const useCustomerChildrenQuery = (
  customerId: number,
  options?: QueryObserverOptions<{ data: { id: number; name: string }[] }>
) => {
  const message = useMessage();

  return useQuery<{ data: { id: number; name: string }[] }>(
    [QueryKey.CustomerChildren, customerId],
    () =>
      backendApi
        .get<{ data: { id: number; name: string }[] }>(`/customers/${customerId}/children`)
        .then((response) => response.data)
        .catch((error) => {
          message.error(error.message, messageDuration.medium);
          return Promise.reject(error);
        }),
    {
      retry: false,
      staleTime: Infinity,
      ...(options && { ...options })
    }
  );
};
