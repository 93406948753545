import React, { useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { PROGRESS_COLOR, RAIL_COLOR } from 'Components/Primitives/Progress/Progress.consts';
import { ProgressProps } from 'Components/Primitives/Progress/Progress';

const StyledProgressCircle = styled.div<{
  size: number;
  showLabel: ProgressProps['showLabel'];
  badgeCoords: { tX: number; tY: number };
  progColor: string;
}>`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  .badge-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
  }

  .badge {
    font-size: 10px;
    background-color: ${({ progColor }) => progColor};
    color: white;
    transform: ${({ badgeCoords }) => `translate(${badgeCoords.tX}px, ${badgeCoords.tY}px)`};
    padding: 2px 4px;
    border-radius: 8px;
    opacity: ${({ showLabel }) => (showLabel === 'hover' ? 0 : 1)};
  }

  &:hover .badge {
    opacity: 1;
  }
`;

const circleSizeByPropSize = {
  small: 32,
  middle: 60,
  large: 140
};

const ProgressCircle: React.FC<Omit<ProgressProps, 'type'>> = ({
  progress,
  size,
  strokeWidth = 4,
  showLabel = 'always',
  progressColor = PROGRESS_COLOR,
  railColor = RAIL_COLOR,
  labelSuffix,
  className
}) => {
  const circleRef = useRef(null);
  const circleSize = useMemo(() => (typeof size === 'number' ? size : circleSizeByPropSize[size]), [size]);

  const circleParams: { center: number; radius: number; circumference: number; progressOffset: number } =
    useMemo(() => {
      const center = circleSize / 2;
      const radius = (circleSize - strokeWidth) / 2;
      const circumference = 2 * Math.PI * radius;
      const progressOffset = ((100 - progress) / 100) * circumference;
      return {
        center,
        radius,
        circumference,
        progressOffset
      };
    }, [circleSize, strokeWidth, progress]);

  const badgeCoords: { tX: number; tY: number } = useMemo(() => {
    const angle = ((progress * 360) / 100 - 90) * (Math.PI / 180);
    return { tX: Math.cos(angle) * circleParams.radius, tY: Math.sin(angle) * circleParams.radius };
  }, [circleParams, progress]);

  return (
    <StyledProgressCircle
      className={className}
      size={circleSize}
      showLabel={showLabel}
      badgeCoords={badgeCoords}
      progColor={progressColor}
    >
      <svg className="svg" width={circleSize} height={circleSize}>
        <circle
          stroke={railColor}
          cx={circleParams.center}
          cy={circleParams.center}
          r={circleParams.radius}
          strokeWidth={strokeWidth}
          fill="transparent"
        />
        <circle
          ref={circleRef}
          stroke={progressColor}
          cx={circleParams.center}
          cy={circleParams.center}
          r={circleParams.radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circleParams.circumference}
          strokeDashoffset={circleParams.progressOffset}
          fill="transparent"
          transform={`rotate(-90 ${circleParams.center} ${circleParams.center})`}
        />
      </svg>
      {showLabel !== 'never' && (
        <div className="badge-wrapper">
          <div className="badge">
            {Math.round(progress)}
            {labelSuffix && <>{labelSuffix}</>}
          </div>
        </div>
      )}
    </StyledProgressCircle>
  );
};

export default ProgressCircle;
