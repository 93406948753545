import { ConditionField } from 'Components/Conditions';
import { PredefinedDateRange } from 'Types/DateRange.types';
import {
  CustomReportEntryMetadata,
  CustomReportFieldGroupMetadata,
  CustomReportFieldMetadata,
  CustomReportConfig,
  ReportEntityTemplate
} from 'Types/Reports.types';
import { camelCase } from 'Utils/functional';

export const getReportFieldKey = (field: ConditionField): string => camelCase(field.id);

type FilterByFn = (fieldEntry: CustomReportFieldMetadata) => boolean;
export const getEntityMetadataFieldMap = (
  metadata: CustomReportEntryMetadata[],
  options?: { filterBy: 'isFilterable' | 'isColumn' | FilterByFn }
): { [key: string]: CustomReportFieldMetadata } => {
  return metadata.reduce((acc: { [key: string]: CustomReportFieldMetadata }, entry) => {
    if (Object.prototype.hasOwnProperty.call(entry, 'entries')) {
      // group entry
      const groupEntry = entry as CustomReportFieldGroupMetadata;
      acc = {
        ...acc,
        ...getEntityMetadataFieldMap(groupEntry.entries, options)
      };
    } else {
      // flat field entry
      const fieldEntry = entry as CustomReportFieldMetadata;
      if (options && options.filterBy === 'isFilterable') {
        if (fieldEntry.isFilterable) acc[fieldEntry.id] = fieldEntry;
      } else if (options && options.filterBy === 'isColumn') {
        if (fieldEntry.isColumn) acc[fieldEntry.id] = fieldEntry;
      } else if (options && typeof options.filterBy === 'function') {
        if (options.filterBy(fieldEntry)) acc[fieldEntry.id] = fieldEntry;
      } else {
        acc[fieldEntry.id] = fieldEntry;
      }
    }
    return acc;
  }, {});
};

export const getReportDataQueryParams = (reportConfig: CustomReportConfig): CustomReportConfig => {
  return {
    ...reportConfig,
    ...(reportConfig.columns.length > 1 && {
      sortBy: [{ columnKey: reportConfig.columns[0].key, direction: 'Asc' }]
    })
  };
};

export const convertReportEntityTemplateToReportConfig = (entity: ReportEntityTemplate): CustomReportConfig => {
  return {
    entity: entity.name,
    arguments: { dateRange: PredefinedDateRange.Past2Weeks },
    columns: entity.defaultFields.map((f) => ({ field: f, key: getReportFieldKey(f) })),
    filter: entity.defaultFilter,
    sortBy: [{ columnKey: getReportFieldKey(entity.defaultFields[0]), direction: 'Asc' }]
  };
};
