import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { TreeSelect as RefTreeSelect } from 'antd';
import type { RenderDOMFunc } from 'rc-select/lib/BaseSelect';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { nonNullable } from 'Utils/functional';
import classNames from 'classnames';
import { getTreeSelectedValues, getTreeValues } from 'Components/Primitives/Tree/TreeSelect/TreeSelectUtils';
import { LabelValueType, TreeSelectValue, TreeNode } from 'Components/Primitives/Tree/Tree.types';
import { Icon } from 'Components/Primitives/Icon/Icon';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import { Colors, times8 } from 'Constants/Styles';
import { Checkbox, CheckboxChangeEvent } from 'Components/Primitives/Checkbox/Checkbox';

export interface TreeSelectProps {
  value?: TreeSelectValue;
  options: TreeNode[];
  selectMultiple?: boolean;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  maxTagCount?: number | 'responsive';
  emptyAsAll: boolean;
  allowClear?: boolean;
  className?: string;
  popupMatchSelectWidth?: boolean;
  treeNodeLabelProp?: 'displayTitle' | 'title';
  dropdownStyle?: CSSProperties;
  'data-testid'?: string;
  maxTagPlaceholder?(additionalValues: LabelValueType[]): ReactNode;
  onSearch?(q: string): void;
  getPopupContainer?: RenderDOMFunc | undefined;
  onChange?(ids: TreeSelectValue): void;
}

const StyledSelectAll = styled.div`
  padding: ${times8()}px;
  margin-bottom: ${times8()}px;
  border-bottom: 1px solid ${Colors.Border};
  button {
    font-weight: 500;
  }
`;

const defaultMaxTagPlaceholder = (additionalValues: LabelValueType[]): ReactNode => <>+{additionalValues.length} ...</>;

const { SHOW_PARENT } = RefTreeSelect;
const DEFAULT_MAX_TAG_COUNT = 12;
type SelectAllState = 'indeterminate' | 'checked' | undefined;

export const TreeSelect: React.FC<TreeSelectProps> = ({
  options: treeData,
  value: defaultValue,
  selectMultiple,
  loading,
  disabled,
  placeholder,
  maxTagCount,
  emptyAsAll,
  allowClear,
  className,
  popupMatchSelectWidth = true,
  treeNodeLabelProp = 'title',
  dropdownStyle,
  'data-testid': dataTestId,
  maxTagPlaceholder,
  onSearch,
  getPopupContainer,
  onChange
}) => {
  const { t } = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<TreeSelectValue>();
  const [sanitized, setSanitzed] = useState<boolean>(false);

  const allSelectableValues = useMemo((): (string | number)[] => getTreeValues(treeData), [treeData]);
  const showAllowClear = useMemo(
    (): boolean => (allowClear || false) && (Array.isArray(value) ? value.length > 0 : value !== undefined),
    [allowClear, value]
  );
  const indicateAllSelected = useMemo(
    (): boolean =>
      selectMultiple === true &&
      ((emptyAsAll && value === undefined) || (Array.isArray(value) && value.length === allSelectableValues.length)),
    [selectMultiple, emptyAsAll, allSelectableValues, value]
  );

  const treeDefaultExpandedKeys = useMemo(
    () =>
      defaultValue
        ? treeData
            .filter((treeNode) =>
              treeNode.children.some((child) =>
                Array.isArray(defaultValue) ? defaultValue.includes(child.value) : defaultValue === child.value
              )
            )
            .map((treeNode) => treeNode.value)
        : [],
    [treeData, defaultValue]
  );

  const selectAllCheckState = useMemo((): SelectAllState => {
    if (!Array.isArray(value)) return undefined;
    const selectedValues = getTreeSelectedValues(value, treeData);
    if (selectedValues.length > 0 && selectedValues.length < allSelectableValues.length) {
      return 'indeterminate';
    } else if (selectedValues.length === allSelectableValues.length) {
      return 'checked';
    } else {
      return undefined;
    }
  }, [treeData, allSelectableValues, value]);

  const dropdownVisibleChange = useCallback(
    (e: boolean) => {
      setIsOpen(e);
      if (e === false) {
        const changeValue = indicateAllSelected ? (emptyAsAll ? null : value) : value || null;
        setValue(changeValue ? changeValue : undefined);
        selectMultiple && onChange && onChange(changeValue || null);
      }
    },
    [value, emptyAsAll, selectMultiple, indicateAllSelected, onChange]
  );

  const onSelectChange = useCallback(
    (selected: TreeSelectValue) => {
      const val = Array.isArray(selected)
        ? selected.length === 0
          ? undefined
          : getTreeSelectedValues(selected, treeData)
        : selected;
      setValue(val);
      onSearch && onSearch('');
      (selectMultiple === false || isOpen === false) && onChange && onChange(val || null);
    },
    [treeData, selectMultiple, isOpen, onSearch, onChange]
  );

  const onSelectAllChange = useCallback(
    (e: CheckboxChangeEvent) => {
      if (e.target.checked) {
        setValue(allSelectableValues);
      } else {
        setValue(undefined);
      }
    },
    [allSelectableValues]
  );

  useEffect(() => {
    if (!sanitized) return;
    setValue(defaultValue || undefined);
  }, [sanitized, defaultValue]);

  //
  // sanitizing the tree select values
  //
  useEffect(() => {
    if (defaultValue && treeData && treeData.length > 0 && !sanitized) {
      const allValuesIncludeParents = getTreeValues(treeData, true);
      if (Array.isArray(defaultValue)) {
        const validValues: (string | number)[] = defaultValue
          .filter(nonNullable)
          .filter((v) => v && allValuesIncludeParents.includes(v));
        if (validValues.length < defaultValue.length) {
          setValue(validValues);
          onChange && onChange(validValues.length === 0 ? null : validValues);
        }
      } else {
        const valueIsValid: boolean = allValuesIncludeParents.includes(defaultValue);
        if (!valueIsValid) {
          setValue(undefined);
          onChange && onChange(null);
        }
      }
      setSanitzed(true);
    }
  }, [defaultValue, treeData, sanitized, onChange]);

  return (
    <RefTreeSelect
      value={value}
      treeData={treeData}
      data-testid={dataTestId || 'tree-select'}
      className={classNames(className, indicateAllSelected && 'indicate-all')}
      treeCheckable={selectMultiple}
      open={isOpen}
      showSearch
      treeDefaultExpandedKeys={treeDefaultExpandedKeys}
      treeNodeFilterProp="searchValue"
      treeNodeLabelProp={treeNodeLabelProp}
      showCheckedStrategy={SHOW_PARENT}
      disabled={loading || disabled}
      loading={loading}
      placeholder={placeholder}
      allowClear={showAllowClear}
      dropdownStyle={{ ...dropdownStyle, minWidth: 300 }}
      maxTagPlaceholder={maxTagPlaceholder || defaultMaxTagPlaceholder}
      maxTagCount={maxTagCount || DEFAULT_MAX_TAG_COUNT}
      suffixIcon={<Icon icon={Icons.ChevronDown} />}
      onChange={onSelectChange}
      onDropdownVisibleChange={dropdownVisibleChange}
      onSearch={onSearch}
      treeExpandAction={selectMultiple ? false : 'click'}
      popupClassName={selectMultiple ? 'tree-multi-select' : 'tree-single-select'}
      popupMatchSelectWidth={popupMatchSelectWidth}
      dropdownRender={(originalNode: ReactNode) => (
        <>
          {selectMultiple && (
            <StyledSelectAll>
              <Checkbox
                data-testid="select-all-checkbox"
                onChange={onSelectAllChange}
                indeterminate={selectAllCheckState === 'indeterminate'}
                checked={selectAllCheckState === 'checked'}
              >
                {t('all')}
              </Checkbox>
            </StyledSelectAll>
          )}
          {originalNode}
        </>
      )}
      getPopupContainer={getPopupContainer}
    />
  );
};
