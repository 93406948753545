import { BASE_CUSTOMER_ICON_URL, BASE_CUSTOMER_LOGO_URL } from 'Types/Customer.types';
import { isJsonString } from 'Utils/functional';

export const baseUrl = window.location.protocol + '//' + window.location.host;

export const addQueryParamToUrl = (url: string, key: string, value: string): string => {
  const urlObj = new URL(url);
  urlObj.searchParams.append(key, value);
  return urlObj.toString();
};

export const getCustomerLogoPath = (domains: string[]): string | undefined =>
  domains.length > 0 ? `${BASE_CUSTOMER_LOGO_URL}${domains[0]}` : undefined;

export const getCustomerIconPath = (domains: string[]): string | undefined =>
  domains.length > 0 ? `${BASE_CUSTOMER_ICON_URL}${domains[0]}` : undefined;

const encodeBase64 = (stringifiedParams: string): string => window.btoa(stringifiedParams);
const decodeBase64 = (base64Str: string): string | undefined => {
  try {
    window.atob(base64Str);
  } catch (e) {
    console.error('Error decoding base64 string', e);
    return undefined;
  }
  return window.atob(base64Str);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeParamsToBase64 = (params: { [key: string]: any }): string => encodeBase64(JSON.stringify(params));

export const decodeParamsFromBase64 = <T>(base64Str: string): T | null => {
  const decodedString = decodeBase64(base64Str);
  return decodedString && isJsonString(decodedString) ? JSON.parse(decodedString) : null;
};

export const subdomainPattern = new RegExp('^[A-Za-z0-9](?:[A-Za-z0-9-.]{0,61}[A-Za-z0-9])?$');

const URLProtocolReg = new RegExp('^(ht|f)tp(s?)://');
export const URLHasProtocol = (url: string): boolean => URLProtocolReg.test(url) === true;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidURL = (str: any): boolean => {
  try {
    new URL(str);
  } catch (e) {
    console.error('Error parsing URL:', e);
    return false;
  }
  return true;
};

export const isIFrame = (): boolean => window.self !== window.top;
