import { useCallback, useEffect, useMemo, useState } from 'react';
import { TreeNode } from 'Components/Primitives';
import { useCustomersEssentialsQuery, useUsersEssentialsQuery } from 'hooks';
import { getUsersTreeSelectOptions } from 'Components/Users/UsersTreeSelect/UsersTreeSelectUtils';
import { getCustomersTreeSelectOptions } from 'Components/Customers/CustomersTreeSelect/CustomersTreeSelectUtils';
import { useEntityRefValuesFromEntityMetadata } from 'Views/Reports/CustomReport/ReportEntity/hooks/useEntityRefValuesFromEntityMetadata';
import { CUSTOMER_ENTITY, PARTICIPANT_ENTITY, STAKEHOLDER_ENTITY, USER_ENTITY } from 'Types/ReportField.types';

const SPECIAL_CASE_ENTITIES: string[] = [CUSTOMER_ENTITY, USER_ENTITY, STAKEHOLDER_ENTITY, PARTICIPANT_ENTITY];

export const useEntityRefSelectValues = (entities: string[]): { getEntityRefValues(entity: string): TreeNode[] } => {
  const [customersTreeNodes, setCustomersTreeNodes] = useState<TreeNode[]>([]);
  const [usersTreeNodes, setUsersTreeNodes] = useState<TreeNode[]>([]);
  const [entitySelectLists, setEntitySelectLists] = useState<Record<string, TreeNode[]>>({});

  const { getEntityRefValuesFromEntityMetadata } = useEntityRefValuesFromEntityMetadata();
  const { data: customers } = useCustomersEssentialsQuery({ enabled: entities.includes(CUSTOMER_ENTITY) });
  const { data: users } = useUsersEssentialsQuery({ enabled: entities.includes(USER_ENTITY) });

  const setEntitySelectValuse = useCallback(
    async (entity: string) => {
      const values = await getEntityRefValuesFromEntityMetadata(entity);
      setEntitySelectLists((cur) => ({ ...cur, [entity]: values }));
    },
    [getEntityRefValuesFromEntityMetadata]
  );

  // exported function
  const getEntityRefValues = useCallback(
    (entity: string): TreeNode[] => {
      return (
        {
          [CUSTOMER_ENTITY]: customersTreeNodes,
          [USER_ENTITY]: usersTreeNodes,
          ...entitySelectLists
        }[entity] || []
      );
    },
    [entitySelectLists, customersTreeNodes, usersTreeNodes]
  );

  // customers
  useEffect(() => {
    if (!customers) return;
    setCustomersTreeNodes(getCustomersTreeSelectOptions(customers.data, ''));
  }, [customers]);

  // users
  useEffect(() => {
    if (!users) return;
    setUsersTreeNodes(getUsersTreeSelectOptions(users.data, '', 'all'));
  }, [users]);

  // generic
  useEffect(() => {
    entities
      .filter((entity) => !SPECIAL_CASE_ENTITIES.includes(entity))
      .forEach((entity) => {
        setEntitySelectValuse(entity);
      });
  }, [entities, setEntitySelectValuse]);

  const res = useMemo(
    (): { getEntityRefValues(entity: string): TreeNode[] } => ({
      getEntityRefValues
    }),
    [getEntityRefValues]
  );

  return res;
};
