import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStakeholdersQuery } from 'hooks';
import { getStakeholdersTreeSelectOptions } from 'Components/Stakeholders/StakeholderSelect/StakeholderSelectUtils';
import { TreeSelect, TreeNode, TreeSelectValue, TreeSelectProps } from 'Components/Primitives';

interface UsersTreeSelectProps {
  customerId: number;
  value?: number | number[] | null;
  selectMultiple?: boolean;
  emptyAsAll?: boolean;
  maxTagCount?: number | 'responsive';
  placeholder?: string;
  allowClear?: boolean;
  dropdownStyle?: CSSProperties;
  getPopupContainer?: TreeSelectProps['getPopupContainer'];
  onChange?(ids: TreeSelectValue | TreeSelectValue[] | null): void;
}

export const StakeholderTreeSelect: React.FC<UsersTreeSelectProps> = ({
  customerId,
  value,
  selectMultiple = true,
  emptyAsAll = true,
  maxTagCount,
  placeholder,
  allowClear,
  dropdownStyle,
  getPopupContainer,
  onChange
}) => {
  const { t } = useTranslation(['common']);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [treeData, setTreeData] = useState<TreeNode[]>([]);

  const { data, isLoading } = useStakeholdersQuery({ customerId: [customerId] });

  useEffect(() => {
    if (!data) return;
    const options = getStakeholdersTreeSelectOptions(data.data, selectMultiple, searchTerm);
    setTreeData(options);
  }, [data, selectMultiple, searchTerm]);

  const onTreeSelectChange = useCallback(
    (e: TreeSelectValue) => {
      onChange && onChange(e);
      setSearchTerm(undefined);
    },
    [onChange]
  );

  const onSearch = useCallback((q: string) => {
    setSearchTerm(q);
  }, []);

  return (
    <TreeSelect
      data-testid="stakeholder-select"
      className="stakeholder-tree-select"
      options={treeData}
      value={value}
      selectMultiple={selectMultiple}
      emptyAsAll={emptyAsAll}
      maxTagCount={maxTagCount}
      placeholder={placeholder || t('stakeholder-select.placeholder')}
      loading={isLoading}
      allowClear={allowClear}
      dropdownStyle={dropdownStyle}
      onChange={onTreeSelectChange}
      getPopupContainer={getPopupContainer}
      onSearch={onSearch}
    />
  );
};
